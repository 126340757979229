import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { CatalogProduct, FileResult, UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import { CustomProductNumberText } from '../../api/models/customer-product.models';
import {
  GetProductDetailDocumentHeadersRequest,
  GetProductDetailDocumentRequest,
  GetProductDetailPdfRequest,
  GetProductInformationAllergensAndAdditionalRequest,
  GetProductInformationDescriptionRequest,
  GetProductInformationPackingAndStorageRequest,
  ProductDetailDocument,
  ProductDetailTransfer,
  ProductInformationAllergensAndAdditional,
  ProductInformationDescription,
  ProductInformationPackingAndStorage,
} from '../../api/models/product-detail.models';
import { GetFooterResultDetail } from '../../api/models/product-footer.models';
import { normalizeKey } from '../../helpers';
import { AppRoute } from '../../models/routing.models';
import { RootState } from '../store';

const productDetailDocumentsAdapter = createEntityAdapter<ProductDetailDocument>({
  selectId: (d: ProductDetailDocument) => normalizeKey(d.DocumentKey),
  sortComparer: (a, b) => a.DocumentName.localeCompare(b.DocumentName),
});
export interface ProductDetailState {
  productDetailHeader?: CatalogProduct;
  productFooter?: GetFooterResultDetail;
  productInformationDescription?: ProductInformationDescription;
  productInformationAllergensAndAdditional?: ProductInformationAllergensAndAdditional;
  packingInformation?: ProductInformationPackingAndStorage;
  routes?: AppRoute[];
  loadingProductDetail: boolean;
  loadingDescriptionDisplay: boolean;
  loadingNumberDisplay: boolean;
  productDetailLoading: boolean;
  productFooterLoading: boolean;
  productDetailDocumentHeadersLoading: boolean;
  productDetailDocumentLoading: boolean;
  productDetailPdfLoading: boolean;
  loadingPackingInformation: boolean;
  editCustomAttributesMode: boolean;
  customProductDescription: string;
  customProductNumbers: CustomProductNumberText[];
  productInformationDescriptionRequest: GetProductInformationDescriptionRequest | undefined;
  productInformationAllergensAndAdditionalRequest: GetProductInformationAllergensAndAdditionalRequest | undefined;
  productInformationPackingAndStorageRequest: GetProductInformationPackingAndStorageRequest | undefined;
  productDetailDocumentHeadersRequest: GetProductDetailDocumentHeadersRequest | undefined;
  productDetailDocuments: EntityState<ProductDetailDocument>;
  productDetailDocumentRequest: GetProductDetailDocumentRequest | undefined;
  productDetailDocument: FileResult | undefined;
  productDetailPdfRequest: GetProductDetailPdfRequest | undefined;
  productDetailPdf: FileResult | undefined;
}

const initialState: ProductDetailState = {
  productDetailHeader: undefined,
  productFooter: undefined,
  productInformationDescription: undefined,
  productInformationAllergensAndAdditional: undefined,
  packingInformation: undefined,
  routes: [],
  loadingProductDetail: false,
  loadingDescriptionDisplay: false,
  loadingNumberDisplay: false,
  productDetailLoading: true,
  productFooterLoading: true,
  productDetailDocumentHeadersLoading: false,
  productDetailDocuments: productDetailDocumentsAdapter.getInitialState(),
  productDetailDocumentLoading: false,
  productDetailPdfLoading: false,
  loadingPackingInformation: false,
  editCustomAttributesMode: false,
  customProductDescription: '',
  customProductNumbers: [],
  productInformationDescriptionRequest: undefined,
  productInformationAllergensAndAdditionalRequest: undefined,
  productInformationPackingAndStorageRequest: undefined,
  productDetailDocumentHeadersRequest: undefined,
  productDetailDocumentRequest: undefined,
  productDetailDocument: undefined,
  productDetailPdfRequest: undefined,
  productDetailPdf: undefined,
};

// Reducers
export const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState: initialState,
  reducers: {
    resetProductDetail: () => {
      return initialState;
    },
    setLoadingProductDetail: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.loadingProductDetail = action.payload;
    },
    setProductDetail: (state: ProductDetailState, action: PayloadAction<CatalogProduct>) => {
      state.productDetailHeader = action.payload;
      state.customProductDescription = action.payload.DisplayProductDescription || '';
      state.customProductNumbers = action.payload.UnitOfMeasureOrderQuantities.map((uom) => {
        return {
          productNumberText: uom.ProductNumberDisplay || '',
          uom: uom.UnitOfMeasure,
          isChanged: false,
        };
      });
    },
    updateCustomDescription: (
      state: ProductDetailState,
      action: PayloadAction<{ productDescription?: string; productNumbers?: { [uom: number]: string } }>
    ) => {
      if (state.productDetailHeader) {
        if (action.payload.productDescription) {
          state.productDetailHeader.CustomProductDescription = action.payload.productDescription;
          state.productDetailHeader.DisplayProductDescription = action.payload.productDescription;
        }
        if (action.payload.productNumbers) {
          state.productDetailHeader.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
            if (action.payload.productNumbers && action.payload.productNumbers[uom.UnitOfMeasure]) {
              uom.ProductNumberDisplay = action.payload.productNumbers[uom.UnitOfMeasure];
            }
          });
        }
        state.customProductNumbers = state.productDetailHeader.UnitOfMeasureOrderQuantities.map((uom) => {
          return {
            productNumberText: uom.ProductNumberDisplay || '',
            uom: uom.UnitOfMeasure,
            isChanged: false,
          };
        });
      }
    },
    clearAdditionalDetails: (state: ProductDetailState) => {
      state.productFooter = initialState.productFooter;
      state.productInformationDescription = initialState.productInformationDescription;
      state.productInformationAllergensAndAdditional = initialState.productInformationAllergensAndAdditional;
      state.packingInformation = initialState.packingInformation;
    },
    setProductInformationDescriptionRequest: (
      state: ProductDetailState,
      action: PayloadAction<GetProductInformationDescriptionRequest | undefined>
    ) => {
      state.productInformationDescriptionRequest = action.payload;
    },
    setProductInformationDescription: (
      state: ProductDetailState,
      action: PayloadAction<ProductInformationDescription>
    ) => {
      state.productInformationDescription = action.payload;
    },
    setProductInformationAllergensAndAdditionalRequest: (
      state: ProductDetailState,
      action: PayloadAction<GetProductInformationAllergensAndAdditionalRequest | undefined>
    ) => {
      state.productInformationAllergensAndAdditionalRequest = action.payload;
    },
    setProductInformationAllergensAndAdditional: (
      state: ProductDetailState,
      action: PayloadAction<ProductInformationAllergensAndAdditional>
    ) => {
      state.productInformationAllergensAndAdditional = action.payload;
    },
    setProductDetailDocumentHeadersRequest: (
      state: ProductDetailState,
      action: PayloadAction<GetProductDetailDocumentHeadersRequest>
    ) => {
      state.productDetailDocumentHeadersRequest = action.payload;
    },
    setProductDetailDocuments: (state: ProductDetailState, action: PayloadAction<ProductDetailDocument[]>) => {
      productDetailDocumentsAdapter.setAll(state.productDetailDocuments, action.payload);
    },
    setProductDetailDocumentRequest: (
      state: ProductDetailState,
      action: PayloadAction<GetProductDetailDocumentRequest | undefined>
    ) => {
      state.productDetailDocumentRequest = action.payload;
    },
    setProductDetailDocumentHeadersLoading: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.productDetailDocumentHeadersLoading = action.payload;
    },
    setProductDetailDocumentIsLoading: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.productDetailDocumentLoading = action.payload;
    },
    setProductDetailDocument: (state: ProductDetailState, action: PayloadAction<FileResult>) => {
      state.productDetailDocument = action.payload;
    },
    setProductDetailPdfRequest: (
      state: ProductDetailState,
      action: PayloadAction<GetProductDetailPdfRequest | undefined>
    ) => {
      state.productDetailPdfRequest = action.payload;
    },
    setProductDetailPdfIsLoading: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.productDetailPdfLoading = action.payload;
    },
    setProductDetailPdf: (state: ProductDetailState, action: PayloadAction<FileResult>) => {
      state.productDetailPdf = action.payload;
    },
    setProductInformationPackingAndStorageRequest: (
      state: ProductDetailState,
      action: PayloadAction<GetProductInformationPackingAndStorageRequest | undefined>
    ) => {
      state.productInformationPackingAndStorageRequest = action.payload;
    },
    setLoadingProductInformationPackingAndStorage: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.loadingPackingInformation = action.payload;
    },
    setProductInformationPackingAndStorage: (
      state: ProductDetailState,
      action: PayloadAction<ProductInformationPackingAndStorage>
    ) => {
      state.packingInformation = action.payload;
    },
    setProductFooter: (state: ProductDetailState, action: PayloadAction<GetFooterResultDetail>) => {
      state.productFooter = action.payload;
    },
    updateProductDetailExtendedPrice: (state: ProductDetailState, action: PayloadAction<ProductDetailTransfer>) => {
      if (
        state.productDetailHeader?.ProductKey &&
        action.payload.productKey &&
        state.productDetailHeader?.ProductKey === action.payload.productKey
      ) {
        state.productDetailHeader?.UnitOfMeasureOrderQuantities.forEach((uom: UnitOfMeasureOrderQuantity) => {
          if (uom.UnitOfMeasure === action.payload.uom) {
            uom.ExtendedPrice = action.payload.extendedPrice;
            uom.Quantity = action.payload.quantity;
            uom.ShowOrderQuantityAlert = action.payload.showOrderQuantityAlert;
          }
        });
      }
    },
    setBreadCrumb: (state: ProductDetailState, action: PayloadAction<AppRoute[]>) => {
      state.routes = action.payload;
    },
    setLoadingDescriptionDisplay: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.loadingDescriptionDisplay = action.payload;
    },
    setLoadingNumberDisplay: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.loadingNumberDisplay = action.payload;
    },
    setProductDetailLoading: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.productDetailLoading = action.payload;
    },
    setProductFooterLoading: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.productFooterLoading = action.payload;
    },
    setEditCustomAttributesMode: (state: ProductDetailState, action: PayloadAction<boolean>) => {
      state.editCustomAttributesMode = action.payload;
    },
    setCustomProductDescription: (state: ProductDetailState, action: PayloadAction<string>) => {
      state.customProductDescription = action.payload;
    },
    setCustomProductNumbers: (state: ProductDetailState, action: PayloadAction<CustomProductNumberText[]>) => {
      state.customProductNumbers = action.payload;
    },
    resetToDefaultCustomValues: (
      state: ProductDetailState,
      action: PayloadAction<{ customDesc?: string; customProdNums?: CustomProductNumberText[] }>
    ) => {
      const { customDesc, customProdNums } = action.payload;
      if (customDesc !== undefined) state.customProductDescription = customDesc;
      if (customProdNums !== undefined) state.customProductNumbers = customProdNums;
      state.editCustomAttributesMode = false;
    },
  },
});

export const { selectAll: selectAllDocuments } = productDetailDocumentsAdapter.getSelectors<RootState>(
  (state: RootState) => state.productDetail.productDetailDocuments
);
