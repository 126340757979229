export const roundedNumberWithCommas = (value: number): string => {
  return roundToNthDecimal(value, 2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addSpacesBetweenWords = (value: string): string => {
  return value.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3');
};

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeFirstAlphabeticalLetter = (value: string): string => {
  const indexOfFirstLetter = value?.match(/[a-zA-Z]/)?.index ?? 0;
  if (indexOfFirstLetter === 0) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  } else {
    return (
      value.slice(0, indexOfFirstLetter) +
      value.charAt(indexOfFirstLetter).toUpperCase() +
      value.slice(indexOfFirstLetter + 1).toLowerCase()
    );
  }
};

export const roundToNthDecimal = (value: number, decimalPlace: number): string => {
  return (Math.round(value * 100) / 100).toFixed(decimalPlace);
};

export const isString = (obj: unknown): boolean => {
  return typeof obj === 'string' || obj instanceof String;
};

export const normalizeKey = (key: string | undefined | null): string => {
  if (key === null) return '-2';
  return key ?? '-1';
};

export const cityStateZip = (
  city: string | undefined,
  state: string | undefined,
  zip: string | undefined
): string | undefined => {
  const addressArray = [];

  addressArray.push(city);
  addressArray.push(city && state ? ', ' : undefined);
  addressArray.push(state);
  addressArray.push(state && zip ? ' ' : undefined);
  addressArray.push(zip);

  return addressArray.filter((a) => a != undefined).join('');
};

export const formatNameAndNumber = (name: string | undefined, number: string | undefined): string => {
  const arr = [];
  arr.push(name);
  arr.push(name && number ? ' - ' : undefined);
  arr.push(number);
  return arr.filter((a) => a != undefined).join('');
};

export const padCurrencyValue = (value: string | number): string => {
  let stringValue: string;

  if (typeof value === 'number') {
    stringValue = value.toString();
  } else {
    stringValue = value;
  }

  const splitValue = stringValue.split('.');

  if (splitValue.length === 0) {
    return stringValue;
  }

  let decimal = splitValue.length > 1 ? splitValue[1] : '';

  while (decimal.length < 2) {
    decimal += '0';
  }

  return `${splitValue[0]}.${decimal}`;
};
