import {
  AreaFeaturePermissionSetting,
  RouteName,
  AppRoutes as Routes,
  selectIsPunchoutUser,
  selectUserIsMultiUnit,
  useAppSelector,
} from 'common';
import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { TitledRoute, TitledRouteProps } from './TitledRouted';
export const ProtectedRoute: FC<TitledRouteProps> = (props: TitledRouteProps) => {
  const settings = useAppSelector((s) => s.user.userSite?.AreaFeaturePermissionSetting);
  const isUserMultiAccount = useAppSelector((s) => selectUserIsMultiUnit(s));
  const isPunchoutUser = useAppSelector(selectIsPunchoutUser);
  const hasAccess = canAccessRoute(props.route, settings, isUserMultiAccount, isPunchoutUser);
  if (hasAccess) return <TitledRoute {...props} />;
  return <Redirect to={Routes[RouteName.Home].Path} />;
};
const canAccessRoute = (
  route: RouteName,
  setting?: AreaFeaturePermissionSetting,
  isUserMultiAccount?: boolean,
  isPunchoutUser?: boolean
): boolean => {
  if (!setting) return false;
  switch (route) {
    // Orders
    case RouteName.OrderHistory:
      return setting.AreaSetting.IsInvoicesEnabled || setting.AreaSetting.IsOrderEntryEnabled;
    case RouteName.RepeatOrder:
      return setting.AreaSetting.IsInvoicesEnabled || setting.AreaSetting.IsOrderEntryEnabled;
    case RouteName.OrderEntry:
    case RouteName.OrderReview:
    case RouteName.OrderConfirmation:
    case RouteName.OrderDetails:
      return setting.AreaSetting.IsOrderEntryEnabled;
    case RouteName.DeliveryDetails:
      return setting.AreaSetting.IsInvoicesEnabled;
    case RouteName.OrderImport:
      return setting.PermissionSetting.CanCreateOrder;
    // Finance
    case RouteName.AccountsReceivables:
      return setting.PermissionSetting.CanAccessARStatementArea;
    case RouteName.Invoices:
      return setting.PermissionSetting.CanAccessInvoiceArea;
    case RouteName.InvoiceDetail:
      return setting.PermissionSetting.CanAccessInvoiceArea;
    case RouteName.Insights:
      return setting.PermissionSetting.CanAccessInvoiceArea || setting.FeatureSetting.CanViewInvoiceInsights;
    case RouteName.InsightsReport:
      return setting.PermissionSetting.CanAccessInvoiceArea || setting.FeatureSetting.CanViewInvoiceInsights;

    // Manage Users
    case RouteName.ManageUsers:
      return setting.PermissionSetting.CanManageUserAccounts;
    case RouteName.ManageUsersExport:
      return setting.PermissionSetting.CanManageUserAccounts;
    // List Management
    case RouteName.ProductList:
    case RouteName.ProductListManagement:
    case RouteName.ProductListExport:
    case RouteName.ProductListDiary:
      return setting.PermissionSetting.CanAccessListMangement;
    case RouteName.AddFromList:
    case RouteName.AddFromVendor:
    case RouteName.ProductListCatalogSearch:
    case RouteName.ProductListImport:
      return setting.PermissionSetting.CanManageList;
    case RouteName.SentMessages:
    case RouteName.ViewSentMessage:
    case RouteName.CreateMessage:
    case RouteName.MessageGroups:
    case RouteName.ViewMessageGroup:
    case RouteName.CreateMessageGroup:
    case RouteName.UpdateMessageGroup:
    case RouteName.DraftMessages:
    case RouteName.ViewDraftMessage:
      return setting.PermissionSetting.CanSendMessages;

    // List Notifications
    case RouteName.ProductListNotifications:
      return (
        setting.PermissionSetting.CanManageList &&
        setting.PermissionSetting.CanAccessListMangement &&
        setting.FeatureSetting.CanViewListNotifications
      );

    // Par Management
    case RouteName.ParManagement:
    case RouteName.ParMaintenance:
      return setting.PermissionSetting.CanAccessParManagement && setting.FeatureSetting.CanViewParManagement;
    case RouteName.VendorManagement:
    case RouteName.VendorDetails:
      return setting.PermissionSetting.CanViewThirdPartyVendors && setting.FeatureSetting.CanViewThirdPartyVendors;
    case RouteName.VendorProductDetail:
      return (
        setting.PermissionSetting.CanAccessListMangement ||
        (setting.PermissionSetting.CanViewThirdPartyVendors && setting.FeatureSetting.CanViewThirdPartyVendors)
      );

    case RouteName.CustomerGroups:
    case RouteName.ViewCustomerGroup:
    case RouteName.CreateCustomerGroup:
      return (isUserMultiAccount && !isPunchoutUser) ?? false;
    default:
      return false;
  }
};
