import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { createCachedSelector, LruObjectCache } from 're-reselect';
import { CatalogProduct, ProductListDetail, toCatalogProductFromVendorProduct, VendorProduct } from '../../api';
import { ProductListSortByType } from '../../api/models/api-shared.enums';
import { UpdateCustomProductRequest, UpdateIsCriticalItemResultDetail } from '../../api/models/customer-product.models';
import { ProductListCategory } from '../../api/models/product-list-category.models';
import { ProductListHeader, ProductListHeaderCustom } from '../../api/models/product-list-header.models';
import {
  ProductListProduct,
  ProductListSortOption,
  SearchProductListRequest,
  SearchProductListResultDetail,
} from '../../api/models/product-list-search.models';
import { normalizeProductKey, updateProductCustomAttributesFromPayload } from '../../helpers/general/product';
import {
  getCategoryData,
  getCategoryProductData,
  getNormalizedProductListGridData,
  getSubCategoryCount,
  getUncategorizedCategory,
  moveCategory,
  moveCategoryProductBetweenCategories,
  removeCategory,
} from '../../helpers/general/product-list';
import { normalizeKey } from '../../helpers/general/string';
import {
  ProductListCategoryCustom,
  ProductListCategoryProduct,
  ProductListGridData,
} from '../../models/product-list.models';
import { RootState } from '../store';

// Adapters
const categoriesAdapter = createEntityAdapter<ProductListCategoryCustom>({
  selectId: (category: ProductListCategoryCustom) => category.Id,
});

const categoryProductsAdapter = createEntityAdapter<ProductListCategoryProduct>({
  selectId: (categoryProduct: ProductListCategoryProduct) => categoryProduct.Id,
});

const productsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => product.Id,
});

const customAttributesProductUpdatesAdapter = createEntityAdapter<UpdateCustomProductRequest>({
  selectId: (partial: UpdateCustomProductRequest) => normalizeProductKey(partial.ProductKey),
});

const recentlyPurchasedProductsAdapter = createEntityAdapter<CatalogProduct>({
  selectId: (cp: CatalogProduct) => normalizeProductKey(cp.ProductKey),
});

// State
export interface ProductListState {
  apiRequest?: SearchProductListRequest;
  productListHeader?: ProductListHeaderCustom;
  categories: EntityState<ProductListCategoryCustom>;
  categoryProducts: EntityState<ProductListCategoryProduct>;
  products: EntityState<ProductListProduct>;
  categoriesBackup?: EntityState<ProductListCategoryCustom>;
  categoryProductsBackup?: EntityState<ProductListCategoryProduct>;
  recentlyPurchasedProducts: EntityState<CatalogProduct>;
  selectedCategories: string[];
  sortByOptions: ProductListSortOption[];
  categorySelectOptions: ProductListCategory[];
  productListLoading: boolean;
  productListEditAllCustomAttributesMode: boolean;
  categoryToEdit?: ProductListCategoryCustom;
  categoryToDelete?: ProductListCategoryCustom;
  customAttributesProductUpdates: EntityState<UpdateCustomProductRequest>;
  productListDialogLoading?: boolean;
  showAddToListDialog?: boolean;
  addToListDialogLoading?: boolean;
  showAddNewListDialog?: boolean;
  showNewListDialogLoading?: boolean;
  isListSequencing?: boolean;
  isAPIProcessing?: boolean;

  modifiedCategoryIds: string[];
}

const initialState: ProductListState = {
  apiRequest: undefined,
  productListHeader: undefined,
  categories: categoriesAdapter.getInitialState(),
  recentlyPurchasedProducts: recentlyPurchasedProductsAdapter.getInitialState(),
  categoryProducts: categoryProductsAdapter.getInitialState(),
  products: productsAdapter.getInitialState(),
  categoriesBackup: undefined,
  categoryProductsBackup: undefined,
  selectedCategories: [],
  sortByOptions: [],
  categorySelectOptions: [],
  productListLoading: true,
  productListEditAllCustomAttributesMode: false,
  categoryToEdit: undefined,
  categoryToDelete: undefined,
  customAttributesProductUpdates: customAttributesProductUpdatesAdapter.getInitialState(),
  productListDialogLoading: false,
  showAddToListDialog: false,
  addToListDialogLoading: false,
  showAddNewListDialog: false,
  showNewListDialogLoading: false,
  isListSequencing: false,
  isAPIProcessing: false,

  modifiedCategoryIds: [],
};

// Reducers
export const productListSlice = createSlice({
  name: 'productList',
  initialState: initialState,
  reducers: {
    resetState: () => {
      selectCategoryByProductListCategoryId.clearCache();
      selectProductListGridDataById.clearCache();
      return initialState;
    },

    resetProductSearchResults: (state: ProductListState) => {
      state.apiRequest = initialState.apiRequest;
      state.categories = categoriesAdapter.getInitialState();
      state.categoryProducts = categoryProductsAdapter.getInitialState();
      state.products = productsAdapter.getInitialState();
      state.productListLoading = initialState.productListLoading;
      state.modifiedCategoryIds = initialState.modifiedCategoryIds;
    },
    setProductSearchResults: (
      state: ProductListState,
      action: PayloadAction<{
        request: SearchProductListRequest;
        result: SearchProductListResultDetail;
      }>
    ) => {
      const { request, result } = action.payload;

      state.apiRequest = request;

      const data = getNormalizedProductListGridData(result.ProductListCategories, true);
      categoriesAdapter.setAll(state.categories, data.categories);
      categoryProductsAdapter.setAll(state.categoryProducts, data.categoryProducts);
      productsAdapter.setAll(state.products, data.products);
    },
    resetGridData: (state: ProductListState) => {
      state.categories = categoriesAdapter.getInitialState();
      state.categoryProducts = categoryProductsAdapter.getInitialState();
      state.products = productsAdapter.getInitialState();
    },
    setProductListHeader: (state: ProductListState, action: PayloadAction<ProductListHeaderCustom>) => {
      state.productListHeader = action.payload;
    },
    // Manage Categories Grid
    manageProductListCategories: (state: ProductListState, action: PayloadAction<ProductListCategory[]>) => {
      const data = getNormalizedProductListGridData(getCategoryData(action.payload));
      categoriesAdapter.setAll(state.categories, data.categories);
    },
    // Search Grid
    searchProductList: (state: ProductListState, action: PayloadAction<SearchProductListResultDetail>) => {
      const data = getNormalizedProductListGridData(action.payload.ProductListCategories, true);
      console.log(data);
      categoriesAdapter.setAll(state.categories, data.categories);
      categoryProductsAdapter.setAll(state.categoryProducts, data.categoryProducts);
      productsAdapter.setAll(state.products, data.products);
    },
    setProductListLoading: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.productListLoading = action.payload;
    },
    setSortByOptions: (state: ProductListState, action: PayloadAction<ProductListSortOption[]>) => {
      state.sortByOptions = action.payload ?? [];
    },
    setCategorySelectOptions: (state: ProductListState, action: PayloadAction<ProductListCategory[]>) => {
      // Note: Intentionally avoiding the use of the custom model here.
      //       Ids are generated and will not be the same between categories in the select and categories in the grid.
      state.categorySelectOptions = action.payload ?? [];
    },
    addCategorySelectOption: (state: ProductListState, action: PayloadAction<ProductListCategory>) => {
      const newOption = action.payload;
      const options = [...state.categorySelectOptions];
      const parentIndex = options.findIndex((o) => o.ProductListCategoryId === newOption.ParentProductListCategoryId);

      let insertIdx = -1;

      if (parentIndex >= 0) {
        // Insert as last child
        const parent = options[parentIndex];
        insertIdx = options.findIndex(
          (o, i) =>
            i > parentIndex && (parent.Level > o.Level || (parent.Level === o.Level && parent.Sequence < o.Sequence))
        );
      }

      if (insertIdx < 0) options.push(newOption);
      else options.splice(insertIdx, 0, newOption);

      state.categorySelectOptions = [...options];
    },
    setCategoryToEdit: (state: ProductListState, action: PayloadAction<ProductListCategoryCustom | undefined>) => {
      state.categoryToEdit = action.payload;
    },
    setCategoryToDelete: (state: ProductListState, action: PayloadAction<ProductListCategoryCustom | undefined>) => {
      state.categoryToDelete = action.payload;
    },
    updateProductList: (state: ProductListState, action: PayloadAction<ProductListHeader>) => {
      if (state.productListHeader) {
        state.productListHeader = {
          ...state.productListHeader,
          ProductListTitle: action.payload.ProductListTitle || '',
          IsPrivate: action.payload.IsPrivate,
        };
      }
    },
    setProductListDialogLoading: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.productListDialogLoading = action.payload;
    },
    // Products
    updateProductIsCriticalItem: (state: ProductListState, action: PayloadAction<UpdateIsCriticalItemResultDetail>) => {
      const productKey = normalizeProductKey(action.payload.ProductKey);
      const product = state.products.entities[productKey];
      if (product) product.IsCriticalItem = action.payload.IsCriticalItem;
    },
    updateProductIsHidden: (
      state: ProductListState,
      action: PayloadAction<{ isHidden: boolean; productKey: string }>
    ) => {
      const productKey = normalizeProductKey(action.payload.productKey);
      const product = state.products.entities[productKey];
      if (product) product.IsHidden = action.payload.isHidden;
    },
    updateCategoryProductIsRetained: (
      state: ProductListState,
      action: PayloadAction<{ isRetained: boolean; productKey: string }>
    ) => {
      const productKey = normalizeProductKey(action.payload.productKey);
      const product = state.products.entities[productKey];
      if (product) product.IsRetained = action.payload.isRetained;
    },
    updateProductIsChecked: (
      state: ProductListState,
      action: PayloadAction<{ isChecked: boolean; productId: string }>
    ) => {
      const product = state.products.entities[action.payload.productId];
      if (product) product.IsChecked = action.payload.isChecked;
    },
    updateProductAllChecked: (state: ProductListState, action: PayloadAction<{ isChecked: boolean }>) => {
      const products = Object.values(state.products.entities);
      products.map((p) => {
        if (p) p.IsChecked = action.payload.isChecked;
        return p;
      });
    },
    updateProductIsEditing: (
      state: ProductListState,
      action: PayloadAction<{ isEditing: boolean; productKey: string }>
    ) => {
      const productKey = normalizeProductKey(action.payload.productKey);
      const product = state.products.entities[productKey];
      if (product) product.Editing = action.payload.isEditing;
    },
    updateProductAllEditing: (state: ProductListState, action: PayloadAction<{ isEditing: boolean }>) => {
      const products = Object.values(state.products.entities);
      products.map((p) => {
        if (p) p.Editing = action.payload.isEditing;
        return p;
      });
    },
    //resequence category products
    resequenceCategoryProducts: (state: ProductListState, action: PayloadAction<{ categoryId: string }>) => {
      //update product sequences in category to account for removed product in state
      const categoryId = action.payload.categoryId;
      const category = selectCategoryById(state.categories, categoryId);
      category?.CategoryProductIds.forEach((item, index) => {
        const categoryProduct = state.categoryProducts.entities[item];
        if (categoryProduct) {
          categoryProduct.Sequence = index;
        }
      });
    },
    // Category Products
    removeCategoryProduct: (
      state: ProductListState,
      action: PayloadAction<{ categoryId: string; productId: string; productDetailId: string }>
    ) => {
      const normalizedCategoryKey = normalizeKey(action.payload.categoryId);
      const normalizedProductKey = normalizeKey(action.payload.productId);
      const category = selectCategoryById(state.categories, normalizedCategoryKey);

      if (!category) return;

      categoriesAdapter.updateOne(state.categories, {
        id: normalizedCategoryKey,
        changes: {
          CategoryProductIds: category.CategoryProductIds.filter((id) => id !== normalizedProductKey),
          ProductListDetailIds: category.CategoryProductIds.filter(
            (id) => id !== normalizeKey(action.payload.productDetailId)
          ),
        },
      });
      categoryProductsAdapter.removeOne(state.categoryProducts, normalizedProductKey);
    },
    sequenceCategoryProduct: (
      state: ProductListState,
      action: PayloadAction<{
        categoryProductId: string;
        categoryId: string;
        index: number;
        sequence: number;
      }>
    ) => {
      const categoryProductId = action.payload.categoryProductId;
      const categoryProduct = state.categoryProducts.entities[categoryProductId];
      if (!categoryProduct) return;

      const oldCategoryId = categoryProduct.CategoryId;
      const newCategoryId = action.payload.categoryId;
      const index = action.payload.index;
      const sequence = action.payload.sequence;

      const fromCategory = state.categories.entities[oldCategoryId];
      const toCategory = state.categories.entities[newCategoryId];

      // Update and maintain state models for future updates
      categoryProduct.Sequence = sequence;
      moveCategoryProductBetweenCategories(state, fromCategory, toCategory, categoryProduct.Id, index);
      categoryProduct.CategoryId = newCategoryId;

      if (fromCategory && !state.modifiedCategoryIds.includes(fromCategory.Id)) {
        state.modifiedCategoryIds.push(fromCategory.Id);
      }
      if (toCategory && !state.modifiedCategoryIds.includes(toCategory.Id)) {
        state.modifiedCategoryIds.push(toCategory.Id);
      }
    },
    // Categories
    setSelectedCategories: (state: ProductListState, action: PayloadAction<string | undefined>) => {
      const categoryId = action.payload;
      if (!categoryId) {
        state.selectedCategories = [];
        return;
      }
      const ids = [...state.categories.ids];
      const categoryIndex = ids.indexOf(categoryId);
      const categoryCount = getSubCategoryCount(state, categoryId, categoryIndex) + 1;
      const removedIds = state.categories.ids.slice(categoryIndex, categoryIndex + categoryCount);
      state.selectedCategories = removedIds as string[];
    },
    toggleSubCategories: (
      state: ProductListState,
      action: PayloadAction<{ sourceItemId: string; isCollapsed: boolean }>
    ) => {
      const ids = state.categories.ids;
      const collapsedIds = new Set<string>();
      collapsedIds.add(action.payload.sourceItemId);

      ids.forEach((id) => {
        const category = state.categories.entities[id];
        if (category && category.ParentId && collapsedIds.has(category.ParentId)) {
          collapsedIds.add(category.Id);
          category.IsCollapsed = action.payload.isCollapsed;
        }
      });
    },
    sequenceCategory: (
      state: ProductListState,
      action: PayloadAction<{
        destinationCategoryId: string;
        selectedCategoryId: string;
      }>
    ) => {
      const destinationCategoryId = action.payload.destinationCategoryId;
      const selectedCategoryId = action.payload.selectedCategoryId;

      const destinationCategory = state.categories.entities[destinationCategoryId];
      const selectedCategory = state.categories.entities[selectedCategoryId];
      if (!destinationCategory || !selectedCategory) return;

      // Re-order grid
      const ids = [...state.categories.ids];

      const selectedCategoryIndex = ids.indexOf(selectedCategoryId);

      const idsToMove = removeCategory(state, ids, selectedCategory, selectedCategoryIndex);
      const insertIndex = ids.indexOf(destinationCategoryId);
      ids.splice(insertIndex, 0, ...idsToMove);

      state.categories.ids = [...ids];

      // Update and maintain state models for future updates
      moveCategory(state, selectedCategoryId, {
        parentId: destinationCategory.ParentId,
        parentProductListCategoryId: destinationCategory.ParentProductListCategoryId,
        sequence: destinationCategory.Sequence,
        level: destinationCategory.Level,
      });
    },
    nestCategory: (
      state: ProductListState,
      action: PayloadAction<{ destinationCategoryId: string; selectedCategoryId: string }>
    ) => {
      const selectedCategoryId = action.payload.selectedCategoryId;
      const destinationCategoryId = action.payload.destinationCategoryId;

      const destinationCategory = state.categories.entities[destinationCategoryId];
      const selectedCategory = state.categories.entities[selectedCategoryId];
      if (!destinationCategory || !selectedCategory) return;

      // Reorder grid
      const ids = [...state.categories.ids];
      const selectedCategoryIndex = ids.indexOf(selectedCategoryId);

      const idsToMove = removeCategory(state, ids, selectedCategory, selectedCategoryIndex);

      const insertIndex = ids.indexOf(destinationCategoryId) + 1;
      if (insertIndex < ids.length) ids.splice(insertIndex, 0, ...idsToMove);
      else ids.push(...idsToMove);
      state.categories.ids = [...ids];

      // Update and maintain state models for future updates
      moveCategory(state, selectedCategoryId, {
        parentId: destinationCategory.Id,
        parentProductListCategoryId: destinationCategory.ProductListCategoryId,
        sequence: 0,
        level: destinationCategory.Level + 1,
      });
    },
    addCategory: (
      state: ProductListState,
      action: PayloadAction<{ newCategory: ProductListCategory; id: string; parentId: string | undefined }>
    ) => {
      const newCategoryCustom = {
        ...action.payload.newCategory,
        Id: action.payload.id,
        ParentId: action.payload.parentId,
        CategoryProductIds: [],
        SortValue: 0,
        ProductListDetailIds: [],
        IsCollapsed: false,
      };

      // Append to the bottom of the grid
      if (!newCategoryCustom.ParentId) {
        categoriesAdapter.addOne(state.categories, newCategoryCustom);
        return;
      }

      // Append to the end of the parent category
      state.categories.entities[newCategoryCustom.Id] = newCategoryCustom;
      const ids = [...state.categories.ids];

      let index = 0;
      let parentCategory = undefined;
      while (index < ids.length) {
        const existingCategory = state.categories.entities[ids[index]];
        if (existingCategory) {
          if (parentCategory && existingCategory.Level < newCategoryCustom.Level) {
            break;
          }
          if (existingCategory.Id === newCategoryCustom.ParentId) {
            parentCategory = existingCategory;
          }
        }
        index++;
      }

      if (index < ids.length) ids.splice(index, 0, newCategoryCustom.Id);
      else ids.push(newCategoryCustom.Id);

      state.categories.ids = [...ids];
    },
    removeCategory: (state: ProductListState, action: PayloadAction<{ categoryId: string }>) => {
      const categoryIdsToRemove = [action.payload.categoryId];
      const startIndex = state.categories.ids.indexOf(action.payload.categoryId);
      const rootCategory = state.categories.entities[action.payload.categoryId];

      if (startIndex >= 0 && rootCategory) {
        for (let i = startIndex + 1; i < state.categories.ids.length; i++) {
          const category = state.categories.entities[state.categories.ids[i]];
          if (!category || category.Level <= rootCategory.Level) break;
          categoryIdsToRemove.push(category.Id);
        }
      }
      categoriesAdapter.removeMany(state.categories, categoryIdsToRemove);
    },
    updateCategoryTitle: (
      state: ProductListState,
      action: PayloadAction<{ updatedCategory: ProductListCategoryCustom }>
    ) => {
      categoriesAdapter.updateOne(state.categories, {
        id: action.payload.updatedCategory.Id,
        changes: { CategoryTitle: action.payload.updatedCategory.CategoryTitle },
      });
    },
    updateCategoryIsCollapsed: (
      state: ProductListState,
      action: PayloadAction<{ isCollapsed: boolean; categoryId: string }>
    ) => {
      const category = state.categories.entities[action.payload.categoryId];
      if (category) category.IsCollapsed = action.payload.isCollapsed;
    },
    updateAllCategoryIsCollapsed: (state: ProductListState, action: PayloadAction<boolean>) => {
      const categories = Object.values(state.categories.entities);
      categories.map((c) => {
        if (c) c.IsCollapsed = action.payload;
        return c;
      });
    },
    // Backup Product List Search
    setProductListSearchBackup: (state: ProductListState) => {
      state.categoriesBackup = state.categories;
      state.categoryProductsBackup = state.categoryProducts;
    },
    restoreProductListSearch: (state: ProductListState) => {
      if (state.categoriesBackup) state.categories = state.categoriesBackup;
      if (state.categoryProductsBackup) state.categoryProducts = state.categoryProductsBackup;
    },
    // Custom attributes
    setProductListEditAllCustomAttributesMode: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.productListEditAllCustomAttributesMode = action.payload;
    },
    clearCustomAttributesProductUpdates: (state: ProductListState) => {
      state.customAttributesProductUpdates = customAttributesProductUpdatesAdapter.getInitialState();
    },
    setCustomAttributesProductUpdates: (
      state: ProductListState,
      action: PayloadAction<UpdateCustomProductRequest[]>
    ) => {
      customAttributesProductUpdatesAdapter.setAll(state.customAttributesProductUpdates, action.payload);
    },
    removeCustomAttributesProductUpdate: (state: ProductListState, action: PayloadAction<string>) => {
      customAttributesProductUpdatesAdapter.removeOne(
        state.customAttributesProductUpdates,
        normalizeProductKey(action.payload)
      );
    },
    upsertCustomAttributesProductUpdate: (
      state: ProductListState,
      action: PayloadAction<UpdateCustomProductRequest>
    ) => {
      if (!action.payload.ProductKey) return;

      const productKey = normalizeProductKey(action.payload.ProductKey);
      const productUpdate = state.customAttributesProductUpdates.entities[productKey];
      const product = state.products.entities[productKey];
      if (product && !productUpdate) {
        customAttributesProductUpdatesAdapter.addOne(state.customAttributesProductUpdates, {
          ...action.payload,
          ProductKey: productKey,
          CustomProductDescription: product.CustomProductDescription,
          UnitOfMeasures: [] || action.payload.UnitOfMeasures,
        });
      }
      action.payload = {
        ...action.payload,
        ProductKey: productKey,
      };

      // if the record doesn't exist... insert with default values
      customAttributesProductUpdatesAdapter.upsertOne(state.customAttributesProductUpdates, action.payload);
    },
    saveEditingCustomAttributesProductUpdate: (
      state: ProductListState,
      action: PayloadAction<UpdateCustomProductRequest>
    ) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!product) return;

      const canEditDescription = product.CanEditProductDescription;
      const canEditItemNumbers = product.CanEditProductNumber;

      const uomOrderQuantities = product.UnitOfMeasureOrderQuantities.map((oo) => {
        const uom = action.payload.UnitOfMeasures?.find((u) => u.UnitOfMeasure == oo.UnitOfMeasure);
        if (!uom) return oo;
        return { ...oo, ProductNumberDisplay: uom.CustomItemNumber?.trim() };
      });

      if (canEditItemNumbers) {
        product.CustomItemNumber = uomOrderQuantities.map((uom) => uom.ProductNumberDisplay).join(' ');
        product.UnitOfMeasureOrderQuantities = uomOrderQuantities;
      }
      if (canEditDescription) {
        product.CustomProductDescription = action.payload.CustomProductDescription?.trim();
        product.DisplayProductDescription =
          action.payload.CustomProductDescription?.trim() || product.ProductDescription;
      }
    },
    updateProductListRecentPurchases: (state: ProductListState, action: PayloadAction<CatalogProduct[]>) => {
      recentlyPurchasedProductsAdapter.setAll(state.recentlyPurchasedProducts, action.payload);
    },
    addRecentPurchasedProductToList: (
      state: ProductListState,
      action: PayloadAction<{
        productListDetail: ProductListDetail;
        product: ProductListProduct;
        sortByType: ProductListSortByType;
      }>
    ) => {
      const productListDetail = action.payload.productListDetail;
      const product = action.payload.product;
      const normalizedProductKey = normalizeProductKey(product.ProductKey);
      let newCategory = false;
      let category = selectCategoryByProductListCategoryId(state, '00000000-0000-0000-0000-000000000000');
      if (!category) {
        category = getUncategorizedCategory();
        newCategory = true;
      }

      const categoryProduct = getCategoryProductData(productListDetail, product, category.Id);
      category = { ...category, CategoryProductIds: [...category.CategoryProductIds, categoryProduct.Id] };

      productsAdapter.addOne(state.products, product);
      categoryProductsAdapter.addOne(state.categoryProducts, categoryProduct);
      recentlyPurchasedProductsAdapter.removeOne(state.recentlyPurchasedProducts, normalizedProductKey);

      if (newCategory) {
        // Add new category at the bottom
        state.categories.entities[category.Id] = category;
        state.categories.ids = [...state.categories.ids, category.Id];
      } else {
        categoriesAdapter.upsertOne(state.categories, category);
      }
    },
    setShowAddToListDialog: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.showAddToListDialog = action.payload;
    },
    setAddToListDialogLoading: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.addToListDialogLoading = action.payload;
    },
    setShowNewListDialog: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.showAddNewListDialog = action.payload;
    },
    setShowNewListDialogLoading: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.showNewListDialogLoading = action.payload;
    },
    setIsListSequencing: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.isListSequencing = action.payload;
    },
    setisAPIProcessing: (state: ProductListState, action: PayloadAction<boolean>) => {
      state.isAPIProcessing = action.payload;
    },
    updateProductCustomAttributes: (state: ProductListState, action: PayloadAction<UpdateCustomProductRequest>) => {
      const product = state.products.entities[normalizeProductKey(action.payload.ProductKey)];
      if (!product) return;

      updateProductCustomAttributesFromPayload(product, action.payload);
    },
    updateVendorProduct: (state: ProductListState, action: PayloadAction<VendorProduct>) => {
      const product = toCatalogProductFromVendorProduct(action.payload);

      const existingProduct = state.products.entities[product.Id];
      if (existingProduct) {
        state.products.entities[product.Id] = product;
      }
    },
    removeVendorProduct: (state: ProductListState, action: PayloadAction<string>) => {
      const existingProduct = state.products.entities[action.payload];
      if (existingProduct) {
        existingProduct.IsRemoved = true;
      }
    },
  },
});

// Adapter Selectors
export const { selectAll: selectAllCategories, selectById: selectCategoryById } = categoriesAdapter.getSelectors<
  EntityState<ProductListCategoryCustom>
>((categories: EntityState<ProductListCategoryCustom>) => categories);

export const { selectAll: selectAllCategoryProducts, selectById: selectCategoryProductById } =
  categoryProductsAdapter.getSelectors<EntityState<ProductListCategoryProduct>>(
    (categoryProducts: EntityState<ProductListCategoryProduct>) => categoryProducts
  );

export const { selectAll: selectAllProductListProducts, selectById: selectProductListProductById } =
  productsAdapter.getSelectors<EntityState<ProductListProduct>>(
    (products: EntityState<ProductListProduct>) => products
  );

// Custom Selectors
export const selectCategoryByProductListCategoryId = createCachedSelector(
  (state: ProductListState) => state.categories,
  (_state: ProductListState, id: string | undefined) => id,
  (state, id) => {
    // Note: The only sort by option with valid and unique ProductListCategoryIds is the custom category sort.
    //       Constraint does not hold for any other sort by options or when filter text is provided.
    const categories = selectAllCategories(state).filter(
      (c) => c.ProductListCategoryId?.toLocaleLowerCase() === id?.toLocaleLowerCase()
    );
    return categories?.[0];
  }
)({
  keySelector: (_state: ProductListState, id: string | undefined) => id,
  cacheObject: new LruObjectCache({ cacheSize: 100 }),
});

export const selectCategoryProductsByProductKey = createSelector(
  (state: ProductListState) => state.categoryProducts,
  (_state: ProductListState, id: string | undefined) => id,
  (state, id) => selectAllCategoryProducts(state).filter((cp) => cp.ProductId === id)
);

export const selectIsEditingCustomAttributesProducts = (state: ProductListState): boolean =>
  Object.values(state.products.entities).filter((p) => p?.Editing).length > 0;

export const selectProductListProductsWithCustomAttributes = (state: ProductListState): ProductListProduct[] =>
  selectAllProductListProducts(state.products).filter((p) => {
    return p.CustomProductDescription || p.CustomItemNumber;
  });

export const { selectAll: selectAllCustomAttributesProductUpdates, selectById: selectCustomAttributesProductUpdate } =
  customAttributesProductUpdatesAdapter.getSelectors<ProductListState>(
    (state: ProductListState) => state.customAttributesProductUpdates
  );

export const selectAllCategoriesCollapsed = createSelector(
  (state: RootState) => state.productList.categories,
  (state) => {
    const categories = selectAllCategories(state);
    if (categories.length === 0) return false;
    return categories.filter((c) => !c.IsCollapsed).length === 0;
  }
);

export const selectAllCheckedCategoryProducts = createSelector(
  (state: RootState) => state.productList.categoryProducts,
  (state: RootState) => state.productList.products,
  (categoryProductsState, productsState) => {
    const categoryProducts = selectAllCategoryProducts(categoryProductsState);
    return categoryProducts.filter((cp) => {
      const product = productsState.entities[cp.ProductId];
      return product && product.IsChecked;
    });
  }
);

export const isEditingCustomAttributes = createSelector(
  (state: RootState) => state.productList.categoryProducts,
  (state: RootState) => state.productList.products,
  (state: RootState) => state.productList.productListEditAllCustomAttributesMode,
  (categoryProductsState, productsState, productListEditAllCustomAttributesMode): boolean => {
    const categoryProducts = selectAllCategoryProducts(categoryProductsState);
    return (
      productListEditAllCustomAttributesMode ||
      categoryProducts.some((cp) => {
        const product = productsState.entities[cp.ProductKey];
        return product && product.Editing;
      })
    );
  }
);

export const selectAllProductListIds = createSelector(
  (state: RootState) => state.productList.categories,
  (state: RootState) => state.productList.apiRequest?.SortByType,
  (categories, sortByType) => {
    const ids = [] as string[];
    const excludeEmptyCategories = sortByType !== ProductListSortByType.ListCategory;
    const includeAllCategoryIds = sortByType === ProductListSortByType.ListCategory;
    categories.ids.forEach((categoryId) => {
      const category = selectCategoryById(categories, categoryId);
      if (!category) return;

      const isCollapsed = category.IsCollapsed;
      const isEmpty = category.CategoryProductIds.length === 0;

      if (isEmpty && excludeEmptyCategories) return;

      if (includeAllCategoryIds || isCollapsed || isEmpty) ids.push(category.Id);
      if (!isCollapsed) ids.push(...category.CategoryProductIds);
    });
    return ids;
  }
);

export const selectProductListGridDataById = createCachedSelector(
  (state: RootState) => state.productList.categories,
  (state: RootState) => state.productList.categoryProducts,
  (state: RootState) => state.productList.products,
  (_state: RootState, id: string) => id, // Id = categoryId OR categoryProductId
  (
    categories: EntityState<ProductListCategoryCustom>,
    categoryProducts: EntityState<ProductListCategoryProduct>,
    products: EntityState<ProductListProduct>,
    id: string
  ) => {
    const category = selectCategoryById(categories, id);
    if (category) return { category: category, categoryProduct: undefined, product: undefined } as ProductListGridData;

    const categoryProduct = selectCategoryProductById(categoryProducts, id);
    if (categoryProduct) {
      const category = selectCategoryById(categories, categoryProduct.CategoryId);
      const product = selectProductListProductById(products, categoryProduct.ProductId);
      if (product) return { category, categoryProduct, product } as ProductListGridData;
    }

    return undefined;
  }
)({
  keySelector: (_state: RootState, id: string) => id,
  cacheObject: new LruObjectCache({ cacheSize: 100 }),
});

export const selectAllProductListGridData = createSelector(
  (state: RootState) => state,
  (state) => {
    const result = [] as ProductListGridData[];
    const ids = selectAllProductListIds(state);
    ids.forEach((id) => {
      const data = selectProductListGridDataById(state, id);
      if (data) result.push(data);
    });
    return result;
  }
);

export const { selectAll: selectAllRecentlyPurchasedProducts } =
  recentlyPurchasedProductsAdapter.getSelectors<ProductListState>(
    (state: ProductListState) => state.recentlyPurchasedProducts
  );
